<template>
    <u-main-section-wide v-bind="$attrs">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </u-main-section-wide>
</template>

<script>
import UMainSectionWide from "@/sets/styles/mains/u-main-section-wide.vue";

export default {
    props: {},
    components: {
        UMainSectionWide,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
