export const initUser = (user = {}) => ({
    ...user,

    _terms: user?._terms || [],

    username: user?.username || null,
    password: user?.password || null,

    name: user?.name || null,
    email: user?.email || null,
    phone: user?.phone || null,
    promoterCode: user?.promoterCode || null,
});

export const initBank = () => ({
    code: null,
    accountNumber: null,
    accountHolder: null,
});

export const initPromoterApprovalInquiry = () => ({
    _terms: [],
    ci: null,
    phone: null,
    bank: initBank(),
    residentRegistrationNumber: null,
});

export const initOrderReceiver = ({ receiver: { name = null, phone = null, email = null, postcode = null, address1 = null, address2 = null } = {} } = {}) => ({
    name,
    phone,
    email,
    postcode,
    address1,
    address2,
});

export const initOrder = (order = {}) => ({
    ...order,
    receiver: initOrderReceiver(order),
    requestMessage: order?.requestMessage || null,
});

export const initPurchase = (purchase = {}) => ({
    ...purchase,
    claimStatus: purchase?.claimStatus || null,
    claimReason: purchase?.claimReason || null,
    claimReasonDetails: purchase?.claimReasonDetails || null,
});


export const initApplication = (application = {}) => ({
    ...application,
    _terms: application?._terms || [],
    name: application?.name || null,
    phone: application?.phone || null,
    birth: application?.birth || null,
    
    email: application?.email || null,
    qualification_type: application?.qualification_type || null,
    spouse: initSpouse(application?.spouse),
    supply_type: application?.supply_type || null,
    supply_category: application?.supply_category || null,
    earning_rank: application?.earning_rank || null,
    region_rank: application?.region_rank || null,
    isHomeless: application?.isHomeless != null ? application.isHomeless : null,
    isAsset: application?.isAsset != null ? application.isAsset : null,
    isCar: application?.isCar != null ? application.isCar : null,
    files: application?.files || {},
});

export const initSpouse = (spouse = {}) => ({
    name : spouse?.name || null,
    phone : spouse?.phone || null,
    birth : spouse?.birth || null,
});