<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" class="icon-svg icon-insta" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M3.4,0C1.5,0,0,1.5,0,3.4v7.2C0,12.5,1.5,14,3.4,14h7.2c1.9,0,3.4-1.5,3.4-3.4V3.4C14,1.5,12.5,0,10.6,0H3.4z M11.7,3.1c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7C11.3,2.3,11.7,2.7,11.7,3.1z M3.6,7 c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4S8.9,10.4,7,10.4S3.6,8.9,3.6,7z"/>
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-xx-small {
        height: 8px;
    }
    &-x-small {
        height: 10px;
    }
    &-small {
        height: 12px;
    }
    &-default {
        height: 14px;
    }
    &-large {
        height: 16px;
    }
    &-x-large {
        height: 18px;
    }
    &-xx-large {
        height: 20px;
    }
}
</style>
