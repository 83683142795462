var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "footer-foot"
  }, [_c('v-container', [_c('v-row', _vm._b({}, 'v-row', Object.assign({}, _vm.footRowAttrs), false), [this.$slots['default'] ? _c('v-col', _vm._b({}, 'v-col', Object.assign({}, _vm.footDefaultAttrs), false), [_vm._t("default")], 2) : _vm._e(), this.$slots['left'] ? _c('v-col', _vm._b({}, 'v-col', Object.assign({}, _vm.footLeftAttrs), false), [_vm._t("left")], 2) : _vm._e(), this.$slots['center'] ? _c('v-col', _vm._b({}, 'v-col', Object.assign({}, _vm.footCenterAttrs), false), [_vm._t("center")], 2) : _vm._e(), this.$slots['right'] ? _c('v-col', _vm._b({}, 'v-col', Object.assign({}, _vm.footRightAttrs), false), [_vm._t("right")], 2) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }