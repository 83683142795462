<template>
    <div class="sitemap">
        <div class="sitemap__contents">
            <v-container>
                <slot name="sitemap-head" />
                <gnb gnbName="sitemap-menu">
                    <template v-for="(_, slotName) in $slots" #[slotName]>
                        <slot :name="slotName" />
                    </template>
                </gnb>
                <slot name="sitemap-foot" />
            </v-container>
        </div>
        <div class="sitemap__overlay"></div>
    </div>
</template>

<script>
import Gnb from "@/components/publish/styles/headers/gnb.vue";

export default {
    props: {},
    components: {
        Gnb,
    },
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$nextTick(function () {
                this.header();
            });
        },

        // header
        header: function () {
            // hamburgerBtnPc 클릭 시
            var HTML = document.querySelector("html");
            var hamburgerBtnPc = document.querySelector(".btn-hamburger--pc");
            function sitemapToggle() {
                HTML.classList.toggle("sitemap-open");
                hamburgerBtnPc.classList.toggle("active");
                for (var i = 0; i < sitemapMenuSubItem.length; i++) {
                    sitemapMenuSubItem[i].classList.remove("open");
                }
            }
            hamburgerBtnPc.addEventListener("click", sitemapToggle);

            // sitemapMenuLink, sitemapMenuSubLink 클릭 시 sitemap 닫기
            var sitemapMenuLink = document.querySelectorAll(".sitemap-menu__link");
            var sitemapMenuSubItem = document.querySelectorAll(".sitemap-menu__sub-item");
            var sitemapMenuSubLink = document.querySelectorAll(".sitemap-menu__sub-link");
            function sitemapClose() {
                HTML.classList.remove("sitemap-open");
                hamburgerBtnPc.classList.remove("active");
                for (var i = 0; i < sitemapMenuSubItem.length; i++) {
                    sitemapMenuSubItem[i].classList.remove("open");
                }
            }
            for (var i = 0; i < sitemapMenuLink.length; i++) {
                sitemapMenuLink[i].addEventListener("click", sitemapClose);
            }
            for (var i = 0; i < sitemapMenuSubLink.length; i++) {
                sitemapMenuSubLink[i].addEventListener("click", sitemapClose);
            }

            // sitemapMenuArrow 클릭 시 sub 열고 닫기
            var sitemapMenuArrow = document.querySelectorAll(".sitemap-menu__arrow");
            function sitemapMenuSubOpen(el) {
                var target = el.currentTarget;
                target.parentNode.classList.toggle("open");
            }
            sitemapMenuArrow.forEach(function (el) {
                el.addEventListener("click", sitemapMenuSubOpen);
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.sitemap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
    &,
    &__contents,
    &__overlay {
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.2s;
    }
    &__contents {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 6;
        display: block;
        width: 100%;
        height: 100vh;
        padding: var(--header-height) 0 60px;
        .container {
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 30px;
                border-left: 24px solid transparent;
                background: var(--v-grey-lighten5);
                background-clip: padding-box;
            }
            &::-webkit-scrollbar-thumb {
                width: 6px;
                border-left: 24px solid transparent;
                background: var(--v-grey-lighten4);
                background-clip: padding-box;
            }
        }
    }
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: -5;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
    }
}
.sitemap-open {
    .sitemap {
        &,
        &__contents,
        &__overlay {
            opacity: 1;
            visibility: visible;
        }
        &__overlay {
            z-index: 5;
        }
    }
}
</style>
