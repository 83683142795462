<template>
    <div class="mobile-nav-head">
        <v-container>
            <slot />
        </v-container>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-nav-head {
    height: var(--header-body-height);
}
</style>
