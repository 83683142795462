var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg icon-blog",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('g', [_c('path', {
    attrs: {
      "d": "M3.7,5.2c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5C4,6.2,4.3,6,4.3,5.7C4.3,5.4,4,5.2,3.7,5.2z"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.9,5.1c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6C9.4,5.4,9.2,5.1,8.9,5.1z"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.5,0H2.5c-1.1,0-2,0.9-2,2v6.6c0,1.1,0.9,2,2,2h3.9l1.3,3.1c0,0,0.1,0.3,0.3,0.3c0.2,0,0.3-0.3,0.3-0.3l1.3-3.1h3.9c1.1,0,2-0.9,2-2V2C15.5,0.9,14.6,0,13.5,0z M5.2,5.8C5.2,7,3.9,7,3.9,7C3.5,7,3.2,6.7,3.2,6.7v0.2H2.3V3.2\t\tc0,0,0,0,0,0v0c0.1,0,0.9,0,0.9,0v1.4C3.4,4.3,4,4.3,4,4.3C5.4,4.4,5.2,5.8,5.2,5.8L5.2,5.8z M6.8,4.4v2.6H5.9V4.4C5.9,4.1,5.5,4,5.5,4V3.1C6.9,3.1,6.8,4.4,6.8,4.4z M8.9,7C8.1,7,7.4,6.4,7.4,5.7s0.7-1.4,1.5-1.4s1.5,0.6,1.5,1.4S9.7,7,8.9,7zM13.7,7c0,0,0,1.3-1.3,1.3H12V7.5h0.2c0,0,0.6,0.1,0.5-0.8c0,0-0.1,0.3-0.9,0.3c0,0-1.1-0.1-1.1-1.2V5.5c0,0,0-1.1,1.2-1.2c0,0,0.5-0.1,0.8,0.3V4.3h0.9V7L13.7,7z"
    }
  }), _c('path', {
    attrs: {
      "d": "M12.3,5.1c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5C12.9,5.4,12.6,5.1,12.3,5.1z"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }