<template>
    <header class="mobile-header">
        <slot />
    </header>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$nextTick(function () {
                this.header();
            });
        },

        // header
        header: function () {
            // hamburgerBtnMobile 클릭 시
            var mobileNav = document.querySelector(".mobile-nav");
            var mobileNavOverlay = document.querySelector(".mobile-nav__overlay");
            var hamburgerBtnMobileOpen = document.querySelector(".btn-hamburger--mobile");
            var hamburgerBtnMobileClose = document.querySelector(".btn-hamburger--mobile.active");
            var gnbMoItem = document.querySelectorAll(".gnb-mo__item");
            function mobileNavOpen() {
                mobileNav.classList.add("open");
            }
            function mobileNavClose() {
                mobileNav.classList.remove("open");
                for (var i = 0; i < gnbMoItem.length; i++) {
                    gnbMoItem[i].classList.remove("open");
                }
            }
            hamburgerBtnMobileOpen.addEventListener("click", mobileNavOpen);
            hamburgerBtnMobileClose.addEventListener("click", mobileNavClose);
            mobileNavOverlay.addEventListener("click", mobileNavClose);
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    -webkit-transition: all ease-out 0.2s;
    transition: all ease-out 0.2s;

    // fixed
    &--fixed {
        position: fixed;
    }
}
</style>
