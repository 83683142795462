<template>
    <mobile-nav-body class="mobile-nav-body--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </mobile-nav-body>
</template>

<script>
import MobileNavBody from "@/sets/styles/headers/mobile-nav-body.vue";

export default {
    props: {},
    components: {
        MobileNavBody,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-nav-body--basic {
    ::v-deep {
        .container {
            padding: 0 !important;
        }

        .gnb-mo {
            &__item {
                border-bottom: 1px solid var(--border-color);
            }
            &__link {
                color: var(--v-grey-darken4) !important;
            }
            &__sub {
                background-color: var(--v-grey-lighten5);
                .gnb-mo__sub {
                    .gnb-mo__sub-link {
                        color: var(--v-grey-base) !important;
                    }
                }
            }
        }
    }
}
</style>
