var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sitemap', {
    staticClass: "sitemap--line",
    scopedSlots: _vm._u([{
      key: "sitemap-head",
      fn: function () {
        return [_c('div', {
          staticClass: "sitemap__bgline"
        }, [_c('span'), _c('span'), _c('span'), _c('span'), _c('span')])];
      },
      proxy: true
    }, {
      key: "arrow",
      fn: function () {
        return [_c('icon-arrow-primary', {
          attrs: {
            "size": "x-small",
            "direction": "down"
          }
        })];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }