<template>
    <footer-foot class="footer-foot--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </footer-foot>
</template>

<script>
import FooterFoot from "@/sets/styles/footers/footer-foot.vue";

export default {
    props: {},
    components: {
        FooterFoot,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.footer-foot--basic {
    color: #fff;
    background-color: var(--v-grey-darken4);
}
</style>
