<template>
    <v-tabs v-model="tabs" v-bind="{ ...attrs_tabs_common, ...$attrs }" :hide-slider="false">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tabs>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

export default {
    props: {
        value: { type: [String, Number] },
    },
    components: {},
    data() {
        return {
            attrs_tabs_common,
        };
    },
    computed: {
        tabs: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
