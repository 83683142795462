<template>
    <div class="main-visual">
        <div class="main-visual__inner">
            <!-- <img class="main-visual__img" src="/images/main/main-visual.jpg" /> -->
            <v-container>
                <div class="logo-bg">
                    <v-row no-gutters justify="center" align="center">
                        <v-col cols="1" md="auto">
                            <v-img max-width="130" src="/images/main/lumino.png" data-aos="slide-left" data-aos-duration="1500" />
                        </v-col>
                        <v-col cols="6" md="auto">
                            <v-img max-width="476" src="/images/main/visual-txt.png" class="w-100 visual-logo" data-aos="fade-in" data-aos-delay="800" />
                            <v-img max-width="576" src="/images/main/visual-logo.png" class="w-100 visual-logo" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000" />
                        </v-col>
                    </v-row>
                </div>
                <u-scrolldown-double-arrow class="main-visual__scrolldown" reverse />
            </v-container>
        </div>
        <!-- <swiper class="swiper" :options="swiperOptions">
            <swiper-slide>
                <v-card flat tile>
                    <div class="main-visual__inner">
                        <div class="main-visual__img d-md-none" style="background-image: url(/images/test.jpg)" />
                        <div class="main-visual__img d-none d-md-block" style="background-image: url(/images/test.jpg)" />
                    </div>
                </v-card>
            </swiper-slide>
            <swiper-slide>
                <v-card flat tile>
                    <div class="main-visual__inner">
                        <div class="main-visual__img d-md-none primary lighten-4" />
                        <div class="main-visual__img d-none d-md-block primary lighten-4" />
                    </div>
                </v-card>
            </swiper-slide>
            <swiper-slide>
                <v-card flat tile>
                    <div class="main-visual__inner">
                        <div class="main-visual__img d-md-none secondary lighten-4" />
                        <div class="main-visual__img d-none d-md-block secondary lighten-4" />
                    </div>
                </v-card>
            </swiper-slide>
            <div class="swiper-control" slot="pagination" @click.prevent>
                <swiper-pagination-primary class="swiper-pagination--left" />
                <swiper-btn-wrap-primary>
                    <v-row class="row--xs">
                        <v-col cols="auto">
                            <swiper-button-primary />
                        </v-col>
                        <v-col cols="auto">
                            <swiper-button-primary next />
                        </v-col>
                    </v-row>
                </swiper-btn-wrap-primary>
            </div>
        </swiper> -->
    </div>
</template>

<script>
import UTitShadow from "@/components/publish/styles/typography/u-tit-shadow.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UScrolldownDoubleArrow from "@/components/publish/styles/scrolldowns/u-scrolldown-double-arrow.vue";

// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import SwiperPaginationPrimary from "@/components/publish/parents/swipers/swiper-pagination-primary.vue";
// import SwiperBtnWrapPrimary from "@/components/publish/parents/swipers/swiper-btn-wrap-primary.vue";
// import SwiperButtonPrimary from "@/components/publish/parents/swipers/swiper-button-primary.vue";
// import BtnArrowPrimary from "@/components/publish/parents/buttons/btn-arrow-primary.vue";

export default {
    components: {
        UTitDefault,
        UTitShadow,
        UTxtDefault,
        UScrolldownDoubleArrow,
        // Swiper,
        // SwiperSlide,
        // SwiperPaginationPrimary,
        // SwiperBtnWrapPrimary,
        // SwiperButtonPrimary,
        // BtnArrowPrimary,
    },
    props: {},
    data() {
        return {
            // swiperOptions: {
            //     allowTouchMove: true,
            //     centeredSlides: true,
            //     spaceBetween: 0,
            //     autoplay: {
            //         delay: 5000,
            //         disableOnInteraction: false,
            //     },
            //     speed: 500,
            //     loop: true,
            //     pagination: {
            //         el: ".swiper-pagination",
            //         clickable: true,
            //         renderBullet: function (index, className) {
            //             function numberAppend(n) {
            //                 return n < 10 ? "0" + n.toString() : n.toString();
            //             }
            //             return '<span class="' + className + '">' + numberAppend(index + 1) + "</span>";
            //         },
            //     },
            //     navigation: {
            //         nextEl: ".main-visual .swiper-button-next",
            //         prevEl: ".main-visual .swiper-button-prev",
            //     },
            // },
        };
    },
    mounted() {
        // let img = n;
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
// main-visual
.main-visual {
    background-image: url("/images/main/main-visual.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 1920px;
        width: 100%;
        height: 380px;
        max-height: 980px;
        margin: auto;
    }
    // &__img {
    //     max-width: calc(100% + 2px);
    //     width: calc(100% + 2px);
    //     height: 100%;
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    //     z-index: -1;
    // }
    &__scrolldown {
        position: absolute;
        bottom: 20px;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        color: #fff;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &__inner {
            height: 400px;
        }
        &__scrolldown {
            position: absolute;
            bottom: 60px;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__inner {
            min-height: 980px;
            height: 100vh;
        }
        &__scrolldown {
            bottom: 100px;
        }
    }
}
// @media (min-width: 1920px) {
//     .main-visual {
//         background-size: contain;
//     }
// }
[data-aos="slide-left"] {
    transform: translate3d(275%, 0, 0);
}

.logo-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    background: url("/images/main/logo-bg.png") no-repeat center;
    background-size: contain;
}

.visual-logo {
    filter: drop-shadow(0 0 10px #000);
}
</style>
