<template>
    <u-divider-group class="divider-group--lg"><slot /></u-divider-group>
</template>

<script>
import UDividerGroup from "@/components/publish/styles/groups/u-divider-group.vue";

export default {
    props: {},
    components: {
        UDividerGroup,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>