<template>
    <lnb class="lnb--all-filled">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </lnb>
</template>

<script>
import Lnb from "@/components/publish/styles/headers/lnb.vue";

export default {
    props: {},
    components: {
        Lnb,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss">
// 사용시 주석을 풀어 사용합니다.
// .header__bg {
//     background-color: rgba(255, 255, 255, 1) !important;
//     border-top: 1px solid var(--v-grey-lighten3);
//     border-bottom: 1px solid var(--v-grey-lighten3);
// }
</style>
<style lang="scss" scoped>
.lnb--all-filled ::v-deep {
    line-height: 1.35;
    background-color: transparent;
    border-top: 1px solid var(--v-grey-lighten3);
    border-bottom: 1px solid var(--v-grey-lighten3);
    padding: 32px 0;
    > li {
        > a {
            font-weight: 400;
            color: var(--v-grey-base);
            padding: 8px;
            &:hover {
                font-weight: 700;
                color: var(--v-primary-base);
            }
        }
    }
}
.gnb__item {
    &.active {
        .lnb {
            background-color: var(--v-grey-lighten5);
        }
    }
}
</style>
