<template>
    <u-language-items-text v-bind="$attrs">
        <slot />
    </u-language-items-text>
</template>

<script>
import ULanguageItemsText from "@/components/publish/styles/headers/u-language-items-text.vue";

export default {
    props: {},
    components: {
        ULanguageItemsText,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
