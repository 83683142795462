<template>
    <header-default v-scroll="fixedScroll" class="header--fixed">
        <slot />
    </header-default>
</template>

<script>
import HeaderDefault from "@/sets/styles/headers/header-default.vue";

export default {
    props: {},
    components: {
        HeaderDefault,
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    mounted() {},
    methods: {
        fixedScroll: function () {
            var header = document.querySelector(".header");
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            if (scrollTop > 2) {
                header.classList.add("scroll-active");
            } else {
                header.classList.remove("scroll-active");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
    // scroll-active
    &.scroll-active.header--fixed {
        .header {
            &-head,
            &-body,
            &-foot {
                background-color: rgba(255, 255, 255, 0.7) !important;
            }
        }
    }
}
</style>
