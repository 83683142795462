<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 14" class="icon-svg icon-facebook" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M6.1,0L6.1,0C4.3,0,2.8,1.5,2.9,3.2l0,0.4l0,2H0.9c-0.3,0-0.5,0.2-0.5,0.5v2.6c0,0.3,0.2,0.5,0.5,0.5h2v4.2c0,0.3,0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5V9.2h1.8c0.3,0,0.5-0.2,0.5-0.4l0.4-2.6c0-0.1,0-0.3-0.1-0.4S9.2,5.6,9.1,5.6H6.9V3.8C6.9,3.7,7,3.6,7,3.6h2.1c0.3,0,0.5-0.2,0.5-0.5V0.5C9.6,0.2,9.4,0,9.1,0H6.1z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-xx-small {
        height: 8px;
    }
    &-x-small {
        height: 10px;
    }
    &-small {
        height: 12px;
    }
    &-default {
        height: 14px;
    }
    &-large {
        height: 16px;
    }
    &-x-large {
        height: 18px;
    }
    &-xx-large {
        height: 20px;
    }
}
</style>
