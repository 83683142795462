<template>
    <div class="footer-body">
        <v-container>
            <v-row v-bind="{ ...bodyRowAttrs }">
                <v-col v-if="this.$slots['default']" v-bind="{ ...bodyDefaultAttrs }">
                    <slot />
                </v-col>
                <v-col v-if="this.$slots['left']" v-bind="{ ...bodyLeftAttrs }">
                    <slot name="left" />
                </v-col>
                <v-col v-if="this.$slots['center']" v-bind="{ ...bodyCenterAttrs }">
                    <slot name="center" />
                </v-col>
                <v-col v-if="this.$slots['right']" v-bind="{ ...bodyRightAttrs }">
                    <slot name="right" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        bodyRowAttrs: { type: Object, default: () => ({}) },
        bodyDefaultAttrs: { type: Object, default: () => ({}) },
        bodyLeftAttrs: { type: Object, default: () => ({}) },
        bodyCenterAttrs: { type: Object, default: () => ({}) },
        bodyRightAttrs: { type: Object, default: () => ({}) },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
