var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h1', {
    staticClass: "logo"
  }, [_c('router-link', _vm._b({}, 'router-link', {
    to: _vm.to,
    title: _vm.title
  }, false), [_c('span', {
    staticClass: "d-sr-only"
  }, [_vm._t("default")], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }