<template>
    <mobile-header-body class="mobile-header-body--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </mobile-header-body>
</template>

<script>
import MobileHeaderBody from "@/sets/styles/headers/mobile-header-body.vue";

export default {
    props: {},
    components: {
        MobileHeaderBody,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-header-body--basic{
    background-color: #fff;
    border-bottom: 1px solid var(--v-grey-lighten3);
}
</style>
