<template>
    <mobile-nav-foot class="mobile-nav-foot--basic">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </mobile-nav-foot>
</template>

<script>
import MobileNavFoot from "@/sets/styles/headers/mobile-nav-foot.vue";

export default {
    props: {},
    components: {
        MobileNavFoot,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mobile-nav-foot--basic {
    padding: 10px 0;
}
</style>
