var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg icon-insta",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 14 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M3.4,0C1.5,0,0,1.5,0,3.4v7.2C0,12.5,1.5,14,3.4,14h7.2c1.9,0,3.4-1.5,3.4-3.4V3.4C14,1.5,12.5,0,10.6,0H3.4z M11.7,3.1c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7C11.3,2.3,11.7,2.7,11.7,3.1z M3.6,7 c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4S8.9,10.4,7,10.4S3.6,8.9,3.6,7z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }