<template>
    <i v-bind="$attrs" class="v-icon" :class="[color ? color : '', size ? 'v-icon--size-' + size : '', variant ? 'material-icons-' + variant : 'material-icons']"><slot /></i>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
        variant: { type: String, default: "" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
