import axios from "@/plugins/axios";

let url = "/api/console/center/downloads";

export default {
    gets(data){
        return axios.get(url, data).then(result => result.data);
    },
    get(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    post(data){
        return axios.post(url, data).then(result => result.data);
    },
    put(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    delete(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },

    upload: {
        post(_download, file){
            let formData = new FormData(); formData.append("upload", file);
            let headers = { "Content-Type": "multipart/form-data" };
            return axios.post(`${url}/${_download}/upload`, formData, { headers }).then(result => result.data);
        },
        delete(_download){
            return axios.delete(`${url}/${_download}/upload`).then(result => result.data);
        }
    }
}
