module.exports = {

    // 공용
    common: {
        "SBA 국제유통센터": "SBA Global Trade Center",
        "국제유통센터": "Global Trade Center",
        "로그인": "Login",
        "로그아웃": "Logout",
        "글쓰기": "Writing",
        "마이페이지": "Mypage",
        "페이지소개": "Introduce Page",
        "매칭상담회": "Business Matching",
        "Q&A 자주하는 질문": "FAQ",
        "공지사항": "Notice",
        "기업정보": "Company Information",
        "상품관리": "My products",
        "1:1 문의": "Support center",
        "개인정보처리방침": "Privacy Policy",
        "셀러 회원 마이페이지": "Seller My Page",
        "바이어 회원 마이페이지": "Buyer My page",

        "자세히 보기": "View More",
        "관리자 문의": "Inquiry",
        "아이디 저장": "Save ID",
        "아이디 찾기": "Find ID",
        "비밀번호 찾기": "Find Password",

        "번호": "No",
        "제목": "Title",
        "작성자": "Writer",
        "작성일": "Date",
        "조회수": "Hit",

        "매칭상담회 설명": "Event Description",
        "참여제품": "Listed products",
        "참여기업": "Participating Company",
        "참여바이어": "Participating Buyer",

        "전체": "All",
        "신청하기": "Request",
        "진행중": "Ongoing",
        "참여중": "Participating",
        "종료": "Expired",
        "신청": "Request",

        "select_all": ":: All ::",
        "선정년도": "Selected year",
        "상품명": "Product Name",
        "뒤로가기": "go back",
        "바로가기": "Click Here",
        "상세내용": "Details",
        "SMC_홍보마당": "SMC buyer page URL",
        "홈페이지": "Website",
        "전성분": "Ingredient",
        "컬러": "color",
        "사이즈": "Size",
        "제조국": "Country of Manufacteur",
        "출시일자": "Date of Release",
        "제조방법": "manufacturing method",
        "어워드_선정일": "Date of 'Seoul-award' awarded",
        "판매사이트": "Sales site",
        "sales_price": "Retail Price",
        "주요특징": "Main Feature",
        "prodcut_essential_information": "Product Information",
        "매칭신청": "Matching Request",
        "기업명": "Company name",
        "제품군": "Product Category",
        "인증구분": "Certificate Type",
        "서울어워드": "SeoulAward",
        "슈퍼루키": "SuperRookie",
        "기타": "Other",
        "업체명": "Company name",
        "대표자이름": "CEO name",
        "카테고리": "Category",
        "주소": "Address",
        "회사소개서": "Letter of introduction",
        "활동지역": "Buyer activity area",
        "바이어유형": "Buyer type",
        "온라인": "Online",
        "오프라인": "Offline",
        "저장": "Save",
        "시간": "Time",
        "저장되었습니다": "Saved",
        "취소": "Cancel",
        "매칭거절": "Decline",

        "시도": "City/State",
        "시구군": "City/gu/gun",

        attachments: "attachments",
        list: "list",
        title: "title",
        confirm: "confirm",
        writing: "writing",
        answer_complete: "Answer complete",
        unanswered: "unanswered",
        number: "number",
        inquiry: "inquiry",
        answer_status: "answer status",
        date_created: "Date Created",
        search: "search",
        required_fields: "Required fields",

        셀러기업모집중: "Recruitment of seller companies",
        바이어신청중: "Buyer application",
        companyClick: '※ Click the company name to view detailed information.'

    },

    // 마이페이지
    mypage: {
        "회원정보수정": "my info",
        support_center: "Support center",
        my_products: "My products",
        my_events: "My events",
        company_information: "Company Information",
        company_profile: "Company profile",
        company_profile_upload: ".pdf, .xlsx, .jpg, .png 50Mb or less can be attached (PDF attachment is recommended)",
        company_profile_warning: "※ In the case of the company introduction, the company can read it. Be sure to delete personal information before uploading.",
        CEO_name: "CEO name",
        company_edit_message1: "If you need to edit your company information, be sure to click the",
        save: "Save",
        company_edit_message2: "button below after editing.",
        buyer_activity_area: "Buyer activity area",
    },

    // 메인페이지
    main: {
        platform: {
            tit: "How to use the platform",
            subtit: "Seller's manual",
            txt: "",
            subtit2: "Buyer's manual",
            txt2: "",
        }
    },

    // 페이지소개
    about: {
        title: "Business Matching Platform",
        intro_txt: "We support the matching between Seoul Awards products and the buyers discovered by SBA Global Trade Center.",
        intro_txt2: "We are a B2B business matching counseling platform with distribution channels for small-and-medium sized enterprises looking for a sales route to distribute/export their products or distributors who are looking for excellent products to export overseas.",
        qna_tit: "How can I check my account information?",
        qna_txt: "We delivered the account information to small-and-medium sized manufacturers via e-mail that you have entered when applying for the Seoul Awards. Please check the e-mail and modify your account information at ‘My Page’ after logging in.",
        qna_tit2: "I want to participate as a SELLER in the business matching session!",
        qna_txt2: "Only companies that participated in the ‘Seoul Awards’ and ‘Super Rookie’ held by the SBA Global Trace Center can join the B2B Business Matching Consultation event.",
        qna_btn2: "Apply to the ‘Seoul Awards’",
        qna_tit3: "I want to participate as a BUYER in the business matching session!",
        qna_txt3: "If you wish to participate as a BUYER, check the link below and apply as a BUYER. We will review your application and contact you individually",
        qna_btn3: "Go to ‘Apply as a BUYER’",
    },

    // 자주묻는질문
    faq: {
        type: {
            이용문의: "inquiry",
            홈페이지문의: "General support",
            개인정보문의: "Technical Support",
            기타: "Account Support",
        }
    },
    schedule_dialog: {
        title: 'Mark your availiability during the event date.',
        sub_title: 'You may change your schedule in [My Page - My event - My schedule]. ',
        buyer: {
            'ex1': "",
            'ex2': "Select a preferred method(Online/ Offline)",
            'ex3': '※ Your online matches will be via zoom, and offline matches will be conducted at SBA Global Trade Center, face to face.'
        },
        seller: {
            ex1: "Schedules below are time slots buyer has chosen.",
            ex2: "Please select an affordable schedule up to three to request",
            ex3: "Matching process will be completed once a buyer has confirmed the schedule.",
            ex4: "※ Click 'Decline',  if you do not wish to proceed.",
        },
        application_question: "Do you wish to accept?",
        application_complete: "Offer Accepted",
        reject_question: "Do you wish to decline?",
        reject_compelete: "Offer Declined",
    }
};