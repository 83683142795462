<template>
    <language-items v-bind="$attrs" icon text class="language-items--icon-text">
        <slot />
    </language-items>
</template>

<script>
import LanguageItems from "@/components/publish/styles/headers/language-items.vue";

export default {
    props: {
        icon: Boolean,
        text: Boolean,
    },
    components: {
        LanguageItems,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
