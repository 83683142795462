var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('divider-group-primary', _vm._l(_vm.links, function (link) {
    return _c('li', {
      key: link.title
    }, [link.href ? _c('a', {
      staticClass: "white--text",
      attrs: {
        "href": link.href,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(link.title))]) : _c('router-link', {
      staticClass: "white--text",
      attrs: {
        "to": link.to
      }
    }, [_vm._v(_vm._s(link.title))])], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }