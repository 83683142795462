var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-visual"
  }, [_c('div', {
    staticClass: "main-visual__inner"
  }, [_c('v-container', [_c('div', {
    staticClass: "logo-bg"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "1",
      "md": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "130",
      "src": "/images/main/lumino.png",
      "data-aos": "slide-left",
      "data-aos-duration": "1500"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "auto"
    }
  }, [_c('v-img', {
    staticClass: "w-100 visual-logo",
    attrs: {
      "max-width": "476",
      "src": "/images/main/visual-txt.png",
      "data-aos": "fade-in",
      "data-aos-delay": "800"
    }
  }), _c('v-img', {
    staticClass: "w-100 visual-logo",
    attrs: {
      "max-width": "576",
      "src": "/images/main/visual-logo.png",
      "data-aos": "fade-up",
      "data-aos-delay": "1000",
      "data-aos-duration": "1000"
    }
  })], 1)], 1)], 1), _c('u-scrolldown-double-arrow', {
    staticClass: "main-visual__scrolldown",
    attrs: {
      "reverse": ""
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }