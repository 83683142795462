import axios from "@/plugins/axios";

let url = "/api/v1/setting";

export default {
    get(){
        return axios.get(url).then(result => result.data);
    },
    getSuveryURL(){
        return axios.get(`${url}/getSuveryURL`).then(result => result.data);
    },
}
