<template>
    <main-section v-bind="$attrs">
        <tit-wrap-primary v-if="this.$slots['tit']" :class="titWrapClass" data-aos="fade-up">
            <slot name="tit" />
        </tit-wrap-primary>

        <slot />
    </main-section>
</template>

<script>
import MainSection from "@/sets/styles/mains/main-section.vue";
import TitWrapPrimary from "@/components/publish/parents/typography/tit-wrap-primary.vue";

export default {
    props: {
        titWrapClass: { type: String },
    },
    components: {
        MainSection,
        TitWrapPrimary,
    },
    data() {
        return {};
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        init: function () {},
    },
};
</script>

<style lang="scss" scoped></style>
