var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sitemap', {
    staticClass: "sitemap--basic",
    scopedSlots: _vm._u([{
      key: "arrow",
      fn: function () {
        return [_c('icon-plus')];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }