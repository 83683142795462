import faqs from "./faqs";
import notifications from "./notifications";
import questions from "./questions";
import downloads from "./downloads"

export default {
    faqs,
    notifications,
    questions,
    downloads
};
