<template>
    <u-tit-wrap-default v-bind="$attrs" :class="[titWrapClass, titWrapSize, titWrapAlign]">
        <u-tit-subtit v-if="this.$slots['TitAdd']" :class="[subTitClass, subTitSize]"><slot name="TitAdd" /></u-tit-subtit>
        <u-tit-default :class="[titClass, titSize]"><slot /></u-tit-default>
        <u-txt-default v-if="this.$slots['TxtAdd']" :class="[txtClass, txtSize]"><slot name="TxtAdd" /></u-txt-default>
    </u-tit-wrap-default>
</template>

<script>
import UTitWrapDefault from "@/components/publish/styles/typography/u-tit-wrap-default.vue";
import UTitDefault from "@/components/publish/styles/typography/u-tit-default.vue";
import UTitSubtit from "@/components/publish/styles/typography/u-tit-subtit.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {
        titWrapClass: { type: String, default: "" },
        titWrapSize: { type: String, default: "" },
        titWrapAlign: { type: String, default: "" },
        subTitClass: { type: String, default: "" },
        subTitSize: { type: String, default: "tit--xs" },
        titClass: { type: String, default: "" },
        titSize: { type: String, default: "" },
        txtClass: { type: String, default: "" },
        txtSize: { type: String, default: "txt--sm" },
    },
    components: {
        UTitWrapDefault,
        UTitDefault,
        UTitSubtit,
        UTxtDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
