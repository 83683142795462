<template>
    <sitemap class="sitemap--line">
        <template #sitemap-head>
            <div class="sitemap__bgline"><span /><span /><span /><span /><span /></div>
        </template>

        <template #arrow>
            <icon-arrow-primary size="x-small" direction="down" />
        </template>
    </sitemap>
</template>

<script>
import Sitemap from "@/sets/styles/headers/sitemap.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

export default {
    props: {},
    components: {
        Sitemap,
        IconArrowPrimary,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep {
    .sitemap {
        &__contents {
            background-color: #fff;
        }
        &__bgline {
            position: fixed;
            top: 0;
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
            width: 100%;
            max-width: var(--header-container);
            height: 100vh;
            span {
                position: absolute;
                top: 0;
                width: 1px;
                height: 100vh;
                background-color: var(--v-grey-lighten4);
                &:nth-child(1) {
                    left: 0;
                }
                &:nth-child(2) {
                    left: 20%;
                }
                &:nth-child(3) {
                    left: 40%;
                }
                &:nth-child(4) {
                    left: 60%;
                }
                &:nth-child(5) {
                    left: 80%;
                }
            }
        }
    }

    .sitemap-menu {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
        margin: 0;
        &__item {
            position: relative;
            flex: 0 0 20%;
            max-width: 20%;
            width: 100%;
            padding: var(--grid-gutter-xl);
            > .sitemap-menu__sub {
                margin-top: 0;
                > .sitemap-menu__sub-item {
                    margin-top: 32px;
                    > .sitemap-menu__sub-link {
                        font-size: 1.8rem;
                        color: var(--v-grey-darken4);
                        &::before {
                            display: none;
                        }
                        &:hover {
                            color: var(--v-primary-base);
                        }
                    }
                }
            }
        }
        &__link {
            position: relative;
            display: inline-block;
            font-size: 2.8rem;
            font-weight: 700;
            color: var(--v-grey-darken4);
            margin-bottom: 40px;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: calc((var(--grid-gutter-xl) + 4px) * -1);
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                display: block;
                width: 8px;
                height: 8px;
                background-color: var(--v-primary-base);
            }
            &:hover {
                color: var(--v-primary-base);
            }
        }
        &__sub-item {
            position: relative;
            margin-top: 10px;
            &:first-child {
                margin-top: 0 !important;
            }
            .sitemap-menu__sub {
                transition: all ease-out 0.2s;
            }

            &.open {
                .sitemap-menu {
                    &__sub {
                        margin-top: 20px;
                    }
                }
                > .sitemap-menu__sub-link,
                > .sitemap-menu__arrow {
                    color: var(--v-primary-base) !important;
                }
                > .sitemap-menu__arrow {
                    .icon-plus::after {
                        transform: translate(-50%, -50%) rotate(0);
                    }
                }
            }
        }
        &__sub-link {
            display: inline-block;
            font-size: 1.6rem;
            font-weight: 500;
            color: var(--v-grey-lighten1);
            transition: all ease-out 0.2s;
            &::before {
                content: "· ";
            }
            &:hover {
                color: var(--v-grey-darken4);
            }
        }
        &__sub-link--toggle {
            padding-right: 30px;
        }
        &__arrow {
            position: absolute;
            top: 0;
            right: 0;
            width: 30px !important;
            height: 26px !important;
            padding: 0 !important;
            .icon::before,
            .icon::after {
                transition: all ease-out 0.15s;
            }
        }
    }
}
</style>
