<template>
    <u-footer-basic class="footer--dark">
        <u-footer-head-basic>
            <template #left>
                <footer-link />
            </template>
            <!-- <template #center>
                <div class="red text-center">center</div>
            </template>
            <template #right>
                <div class="red">right</div>
            </template> -->
        </u-footer-head-basic>
        <u-footer-body-basic>
            <template #left>
                <v-row class="row--sm">
                    <v-col cols="12">
                        <u-txt-default class="txt--sm white--text font-weight-bold">주식회사에이치앤비개발</u-txt-default>
                    </v-col>
                    <v-col cols="12">
                        <footer-info />
                    </v-col>
                    <v-col cols="12">
                        <u-txt-default class="txt--xs grey--text text--lighten-1">Copyright 2023. All Rights Reserved.</u-txt-default>
                    </v-col>
                </v-row>
            </template>
            <!-- <template #center>
                <div class="red">center</div>
            </template> -->
            <template #right>
                <v-row justify="space-between" align-md="end" class="flex-column">
                    <v-col cols="auto">
                        <logo-secondary class="ml-md-auto">Lumino 816</logo-secondary>
                    </v-col>
                    <!-- <v-col cols="auto" class="d-flex mt-auto">
                        <v-row class="row--sm">
                            <v-col cols="auto">
                                <v-btn x-small fab color="grey" href="https://blog.naver.com/" target="_blank"><icon-blog /></v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn x-small fab color="grey" href="https://www.youtube.com/" target="_blank"><icon-youtube /></v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn x-small fab color="grey" href="https://www.facebook.com/" target="_blank"><icon-facebook /></v-btn>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn x-small fab color="grey" href="https://www.instagram.com/" target="_blank"><icon-insta /></v-btn>
                            </v-col>
                        </v-row>
                    </v-col> -->
                </v-row>
            </template>
        </u-footer-body-basic>
        <!-- <u-footer-foot-basic>
            <template #left>
                <div class="red">left</div>
            </template>
            <template #center>
                <div class="red">center</div>
            </template>
            <template #right>
                <div class="red">right</div>
            </template>
        </u-footer-foot-basic> -->
    </u-footer-basic>
</template>

<script>
import LogoSecondary from "@/components/publish/parents/headers/logo-secondary.vue";

import UFooterBasic from "@/sets/styles/footers/u-footer-basic.vue";
import UFooterHeadBasic from "@/sets/styles/footers/u-footer-head-basic.vue";
import UFooterBodyBasic from "@/sets/styles/footers/u-footer-body-basic.vue";
import UFooterFootBasic from "@/sets/styles/footers/u-footer-foot-basic.vue";

import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";
import UTxtMedium from "@/components/publish/styles/typography/u-txt-medium.vue";
import FooterLink from "@/components/publish/styles/footers/footer-link.vue";
import FooterInfo from "@/components/publish/styles/footers/footer-info.vue";
import IconBlog from "@/components/publish/styles/icons/icon-blog.vue";
import IconYoutube from "@/components/publish/styles/icons/icon-youtube.vue";
import IconFacebook from "@/components/publish/styles/icons/icon-facebook.vue";
import IconInsta from "@/components/publish/styles/icons/icon-insta.vue";

export default {
    components: {
        LogoSecondary,

        UFooterBasic,
        UFooterHeadBasic,
        UFooterBodyBasic,
        UFooterFootBasic,

        UTxtDefault,
        UTxtMedium,
        FooterLink,
        FooterInfo,
        IconBlog,
        IconYoutube,
        IconFacebook,
        IconInsta,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
