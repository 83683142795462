<template>
    <v-tab v-bind="$attrs" class="v-tab--filled">
        <template v-for="(_, slotName) in $slots" #[slotName]>
            <slot :name="slotName" />
        </template>
    </v-tab>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-tab--filled {
    background-color: transparent !important;
    &.v-tab{
        &--active {
            color: #fff !important;
            background-color: var(--v-primary-base) !important;
        }
    }
}
</style>
