var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-footer-basic', {
    staticClass: "footer--dark"
  }, [_c('u-footer-head-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('footer-link')];
      },
      proxy: true
    }])
  }), _c('u-footer-body-basic', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm"
        }, [_c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-default', {
          staticClass: "txt--sm white--text font-weight-bold"
        }, [_vm._v("주식회사에이치앤비개발")])], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('footer-info')], 1), _c('v-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('u-txt-default', {
          staticClass: "txt--xs grey--text text--lighten-1"
        }, [_vm._v("Copyright 2023. All Rights Reserved.")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "right",
      fn: function () {
        return [_c('v-row', {
          staticClass: "flex-column",
          attrs: {
            "justify": "space-between",
            "align-md": "end"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('logo-secondary', {
          staticClass: "ml-md-auto"
        }, [_vm._v("Lumino 816")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }