var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('main-section', _vm._b({}, 'main-section', _vm.$attrs, false), [this.$slots['tit'] ? _c('tit-wrap-secondary', {
    class: _vm.titWrapClass
  }, [_vm._t("tit")], 2) : _vm._e(), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }