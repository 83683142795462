var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-tit-wrap-default', _vm._b({
    class: [_vm.titWrapClass, _vm.titWrapSize, _vm.titWrapAlign]
  }, 'u-tit-wrap-default', _vm.$attrs, false), [this.$slots['TitAdd'] ? _c('u-tit-subtit', {
    class: [_vm.subTitClass, _vm.subTitSize]
  }, [_vm._t("TitAdd")], 2) : _vm._e(), _c('u-tit-default', {
    class: [_vm.titClass, _vm.titSize]
  }, [_vm._t("default")], 2), this.$slots['TxtAdd'] ? _c('u-txt-default', {
    class: [_vm.txtClass, _vm.txtSize]
  }, [_vm._t("TxtAdd")], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }