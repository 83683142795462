var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sitemap"
  }, [_c('div', {
    staticClass: "sitemap__contents"
  }, [_c('v-container', [_vm._t("sitemap-head"), _c('gnb', {
    attrs: {
      "gnbName": "sitemap-menu"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$slots, function (_, slotName) {
      return {
        key: slotName,
        fn: function () {
          return [_vm._t(slotName)];
        },
        proxy: true
      };
    })], null, true)
  }), _vm._t("sitemap-foot")], 2)], 1), _c('div', {
    staticClass: "sitemap__overlay"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }