<template>
    <div v-if="gnbName == 'gnb'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, i) in gnbs">
                <li v-if="!item.children" :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link'">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                    <component :is="lnbTag" class="lnb--empty"></component>
                </li>

                <li v-else :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        <h2>{{ item.title }}</h2>
                    </router-link>

                    <component :is="lnbTag">
                        <li v-for="(child, j) in item.children" :key="j">
                            <a v-if="child.href" :href="child.href" target="_blank">{{ child.title }}</a>
                            <router-link v-else :to="child.path">{{ child.title }}</router-link>
                        </li>
                    </component>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'gnb-mo'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, i) in gnbs">
                <li v-if="!item.children" :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link'">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                </li>

                <li v-else :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        {{ item.title }}
                    </a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                    <v-btn class="v-btn--text-fit" :class="gnbName + '__arrow'">
                        <icon-arrow-primary size="x-small" direction="down" />
                    </v-btn>

                    <ul :class="gnbName + '__sub'">
                        <template v-for="(child, j) in item.children">
                            <li v-if="!child.children" :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link'">
                                    {{ child.title }}
                                </router-link>
                            </li>

                            <li v-else :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</router-link>

                                <ul :class="gnbName + '__sub'">
                                    <li v-for="grandChild in child.children" :key="grandChild.title" :class="gnbName + '__sub-item'">
                                        <a v-if="grandChild.href" :href="grandChild.href" target="_blank" :class="gnbName + '__sub-link'">{{ grandChild.title }}</a>
                                        <router-link v-else :to="grandChild.path" :class="gnbName + '__sub-link'">{{ grandChild.title }}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'sitemap-menu'" :class="gnbName + '-wrap'">
        <ul :class="[gnbName, gnbClass]">
            <template v-for="(item, i) in gnbs">
                <li v-if="!item.children" :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link'">{{ item.title }}</a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link'">
                        <h2>{{ item.title }}</h2>
                    </router-link>
                </li>

                <li v-else :key="i" :class="gnbName + '__item'">
                    <a v-if="item.href" :href="item.href" target="_blank" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        {{ item.title }}
                    </a>
                    <router-link v-else :to="item.path" :class="gnbName + '__link ' + gnbName + '__link--toggle'">
                        <h2>{{ item.title }}</h2>
                    </router-link>

                    <ul :class="gnbName + '__sub'">
                        <template v-for="(child, j) in item.children">
                            <li v-if="!child.children" :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link'">
                                    {{ child.title }}
                                </router-link>
                            </li>

                            <li v-else :key="j" :class="gnbName + '__sub-item'">
                                <a v-if="child.href" :href="child.href" target="_blank" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</a>
                                <router-link v-else :to="child.path" :class="gnbName + '__sub-link ' + gnbName + '__sub-link--toggle'">{{ child.title }}</router-link>
                                <v-btn class="v-btn--text-fit" :class="gnbName + '__arrow'">
                                    <slot name="arrow" />
                                </v-btn>

                                <ul :class="gnbName + '__sub'">
                                    <li v-for="grandChild in child.children" :key="grandChild.title" :class="gnbName + '__sub-item'">
                                        <a v-if="grandChild.href" :href="grandChild.href" target="_blank" :class="gnbName + '__sub-link'">{{ grandChild.title }}</a>
                                        <router-link v-else :to="grandChild.path" :class="gnbName + '__sub-link'">{{ grandChild.title }}</router-link>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                </li>
            </template>
        </ul>
    </div>
    <div v-else-if="gnbName == 'sub-tab'" :class="gnbName + '-wrap'">
        <v-container>
            <template v-for="item in gnbs">
                <template v-if="item.title == itemTitle">
                    <component :is="tabsTag" :key="item.title" v-bind="{ ...tabsAttrs }">
                        <component :is="tabTag" v-for="child in item.children" :key="child.title" v-bind="tabAttrs" :to="child.path" exact-path :class="[tabActive == child.title ? 'v-tab--active' : '', tabAttrs.class]">{{ child.title }}</component>
                    </component>
                </template>
            </template>
        </v-container>
    </div>
</template>

<script>
import { attrs_tabs_common } from "@/assets/variables";

import Lnb from "@/components/publish/styles/headers/lnb.vue";
import ULnbToplineBorder from "@/components/publish/styles/headers/u-lnb-topline-border.vue";
import ULnbAllFilled from "@/components/publish/styles/headers/u-lnb-all-filled.vue";
import ULnbAllBorder from "@/components/publish/styles/headers/u-lnb-all-border.vue";
import ULnbAllOpacityBlack from "@/components/publish/styles/headers/u-lnb-all-opacity-black.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

import UTabsBorder from "@/components/publish/styles/tabs/u-tabs-border.vue";
import UTabsDot from "@/components/publish/styles/tabs/u-tabs-dot.vue";
import UTabsFilled from "@/components/publish/styles/tabs/u-tabs-filled.vue";
import UTabsSlider from "@/components/publish/styles/tabs/u-tabs-slider.vue";
import UTabButton from "@/components/publish/styles/tabs/u-tab-button.vue";
import UTabDot from "@/components/publish/styles/tabs/u-tab-dot.vue";
import UTabFilled from "@/components/publish/styles/tabs/u-tab-filled.vue";
import UTabPunch from "@/components/publish/styles/tabs/u-tab-punch.vue";

export default {
    props: {
        gnbName: String,
        gnbClass: String,
        lnbTag: String,

        itemTitle: String,
        tabActive: String,
        tabsTag: String,
        tabsAttrs: { type: Object, default: () => ({}) },
        tabTag: String,
        tabAttrs: { type: Object, default: () => ({}) },
    },
    components: {
        Lnb,
        ULnbToplineBorder,
        ULnbAllFilled,
        ULnbAllBorder,
        ULnbAllOpacityBlack,
        IconArrowPrimary,

        UTabsBorder,
        UTabsDot,
        UTabsFilled,
        UTabsSlider,
        UTabButton,
        UTabDot,
        UTabFilled,
        UTabPunch,
    },
    data: function () {
        return {
            gnbs: [
                {
                    title: "사업안내",
                    path: "/business/overview",
                    children: [
                        {
                            title: "사업개요",
                            path: "/business/overview",
                        },
                        {
                            title: "오시는길",
                            path: "/business/direction",
                        },
                        {
                            title: "공급안내",
                            path: "/business/supply",
                        },
                    ],
                },
                {
                    title: "단지안내",
                    path: "/complex/layout",
                    children: [
                        {
                            title: "호수 배치도",
                            path: "/complex/layout",
                        },
                        {
                            title: "층별 호수 배치도",
                            path: "/complex/floor",
                        },
                        {
                            title: "단위세대 평면안내",
                            path: "/complex/unit",
                        },
                        {
                            title: "근린생활시설 평면도",
                            path: "/complex/neighborhood",
                        },
                    ],
                },
                {
                    title: "청약안내",
                    path: "/subscription/requirement",
                    children: [
                        {
                            title: "임차인 자격요건",
                            path: "/subscription/requirement",
                        },
                        {
                            title: "임대조건",
                            path: "/subscription/condition",
                        },
                        {
                            title: "모집공고문",
                            path: "/subscription/recruitment",
                        },
                        {
                            title: "청약일정",
                            path: "/subscription/schedule",
                        },
                        {
                            title: "제출서류",
                            path: "/subscription/document",
                        },
                    ],
                },
                {
                    title: "입주관심등록",
                    path: "/form/registration",
                },
                {
                    title: "청약신청",
                    path: "/application",
                },
                {
                    title: "고객센터",
                    path: "/center/notice",
                    children: [
                        {
                            title: "공지사항",
                            path: "/center/notice",
                        },
                        // {
                        //     title: "문의 게시판",
                        //     path: "/center/inquiry",
                        // },
                        {
                            title: "Q&A 자주하는 질문",
                            path: "/center/faqs",
                        },
                        {
                            title: "서식 다운로드",
                            path: "/center/download",
                        },
                    ],
                },
                // {
                //     title: "가이드",
                //     path: "/guide",
                //     children: [
                //         {
                //             title: "예시페이지",
                //             path: "/ex",
                //         },
                //         {
                //             title: "서브메뉴명1",
                //             href: "/",
                //             children: [
                //                 {
                //                     title: "서브서브메뉴명1",
                //                     path: "/",
                //                 },
                //                 {
                //                     title: "서브서브메뉴명2",
                //                     path: "/",
                //                 },
                //             ],
                //         },
                //         {
                //             title: "서브메뉴명2",
                //             path: "/",
                //         },
                //         {
                //             title: "서브메뉴명3",
                //             path: "/",
                //         },
                //         {
                //             title: "서브메뉴명4",
                //             path: "/",
                //         },
                //     ],
                // },
                // {
                //     title: "폼메일",
                //     path: "/default-form",
                // },
                // {
                //     title: "약관",
                //     path: "/privacy-policy",
                //     children: [
                //         {
                //             title: "개인정보처리방침",
                //             path: "/privacy-policy",
                //         },
                //         {
                //             title: "서브메뉴명1",
                //             path: "/",
                //         },
                //         {
                //             title: "서브메뉴명2",
                //             path: "/",
                //         },
                //         {
                //             title: "서브메뉴명3",
                //             path: "/",
                //         },
                //     ],
                // },
            ],

            attrs_tabs_common,
        };
    },
};
</script>

<style lang="scss" scoped>
// gnb
.gnb {
    display: none;
}
@media (min-width: 1200px) {
    .gnb {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        &__item {
            position: relative;
        }
        &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            min-height: var(--header-body-height);
            font-size: var(--gnb-font-size);
            font-weight: var(--gnb-font-weight);
            line-height: var(--gnb-line-height);
            color: var(--v-grey-darken4);
            padding: var(--gnb-padding-y) var(--gnb-padding-x);
            -webkit-transition: all ease-out 0.2s;
            -ms-transition: all ease-out 0.2s;
            transition: all ease-out 0.2s;
        }
        h2 {
            font-size: inherit;
        }

        // light
        &--light {
            ::v-deep {
                .gnb__link {
                    color: #fff;
                }
            }
        }
    }
}

// gnb-mo
.gnb-mo {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__item {
        position: relative;
        &.open {
            .gnb-mo__sub {
                height: auto;
                opacity: 1;
                visibility: visible;
                padding: 8px 0;
            }
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        height: 54px;
        font-size: 1.4rem;
        padding: 0 var(--container-gutter);
    }
    &__link--toggle {
        padding-right: calc(var(--container-gutter) + 30px);
    }
    &__arrow {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        width: calc(var(--container-gutter) + 30px) !important;
        height: 54px !important;
        border-radius: 0 !important;
    }
    &__sub {
        height: 0;
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: all ease-out 0.2s;
        .gnb-mo__sub {
            padding: 0 0 0 var(--container-gutter) !important;
        }
    }
    &__sub-link {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        padding: 6px var(--container-gutter);
    }
}

// sitemap-menu
.sitemap-menu {
    h2 {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
    }
    &__sub-item {
        .sitemap-menu__sub {
            height: 0;
            margin: 0;
            overflow: hidden;
            opacity: 0;
            visibility: hidden;
            transition: all ease-out 0.2s;
        }

        &.open {
            .sitemap-menu {
                &__sub {
                    height: auto;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}
</style>
