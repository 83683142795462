<template>
    <tit v-bind="$attrs"><slot /></tit>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";

export default {
    props: {},
    components: {
        Tit,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.tit {
    text-shadow: 3px 3px 3px rgba(0,0,0,0.4);
}
</style>
