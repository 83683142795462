<template>
    <mobile-header-default class="mobile-header--basic">
        <slot />
    </mobile-header-default>
</template>

<script>
import MobileHeaderDefault from "@/sets/styles/headers/mobile-header-default.vue";

export default {
    props: {},
    components: {
        MobileHeaderDefault,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
