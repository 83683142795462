<template>
    <mobile-header-default v-scroll="fixedScroll" class="mobile-header--fixed">
        <slot />
    </mobile-header-default>
</template>

<script>
import MobileHeaderDefault from "@/sets/styles/headers/mobile-header-default.vue";

export default {
    props: {},
    components: {
        MobileHeaderDefault,
    },
    data() {
        return {
            window: {
                width: 0,
                height: 0,
            },
        };
    },
    mounted() {},
    methods: {
        fixedScroll: function () {
            var mobileHeader = document.querySelector(".mobile-header");
            var scrollTop = window.scrollY || document.documentElement.scrollTop;
            if (scrollTop > 2) {
                mobileHeader.classList.add("scroll-active");
            } else {
                mobileHeader.classList.remove("scroll-active");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-header {
    &-head,
    &-body,
    &-foot {
        -webkit-transition: all ease-out 0.2s;
        -ms-transition: all ease-out 0.2s;
        transition: all ease-out 0.2s;
    }
    // 스크롤시
    &.scroll-active {
        ::v-deep {
            .mobile-header {
                &-head,
                &-body,
                &-foot {
                    background-color: rgba(255, 255, 255, 0.8) !important;
                }
                &-body {
                    border-bottom: 1px solid rgba(221, 221, 221, 0.6) !important;
                }
            }
        }
    }
}
</style>
