var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg icon-facebook",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 10 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M6.1,0L6.1,0C4.3,0,2.8,1.5,2.9,3.2l0,0.4l0,2H0.9c-0.3,0-0.5,0.2-0.5,0.5v2.6c0,0.3,0.2,0.5,0.5,0.5h2v4.2c0,0.3,0.2,0.5,0.5,0.5h3c0.3,0,0.5-0.2,0.5-0.5V9.2h1.8c0.3,0,0.5-0.2,0.5-0.4l0.4-2.6c0-0.1,0-0.3-0.1-0.4S9.2,5.6,9.1,5.6H6.9V3.8C6.9,3.7,7,3.6,7,3.6h2.1c0.3,0,0.5-0.2,0.5-0.5V0.5C9.6,0.2,9.4,0,9.1,0H6.1z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }