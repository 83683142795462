<template>
    <div class="divider-group">
        <ul>
            <slot />
        </ul>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.divider-group {
    overflow: hidden;
    > ul {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        color: var(--txt-color);
        margin: -2px -8px;
        > li {
            position: relative;
            font-size: 1.4rem;
            padding: 2px 8px;
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                -webkit-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
                display: block;
                width: 1px;
                height: 12px;
                background-color: rgba(221, 221, 221, 0.15);
            }
            &:first-child {
                &::before {
                    display: none !important;
                }
            }
            a {
                display: block;
                padding: 4px 2px;
                line-height: 1;
            }
        }
    }

    &--lg {
        > ul {
            margin: 0 -12px;
            > li {
                font-weight: 700;
                padding: 0 12px;
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .divider-group {
        > ul {
            margin: 0 -12px;
            > li {
                padding: 0 12px;
            }
        }

        &--lg {
            > ul {
                margin: 0 -24px;
                > li {
                    padding: 0 24px;
                }
            }
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
