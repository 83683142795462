<template>
    <svg v-bind="$attrs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14" class="icon-svg icon-youtube" :class="[color ? color : '', size ? 'icon--size-' + size : '']">
        <path d="M15.7,2.3c-0.2-0.7-0.7-1.2-1.4-1.4C13,0.6,8,0.6,8,0.6s-5,0-6.3,0.3C1.1,1.1,0.5,1.7,0.3,2.3C0,3.6,0,7,0,7 s0,3.4,0.3,4.7c0.2,0.7,0.7,1.2,1.4,1.4C3,13.4,8,13.4,8,13.4s5,0,6.3-0.3c0.7-0.2,1.2-0.7,1.4-1.4C16,10.4,16,7,16,7
	S16,3.6,15.7,2.3z M6.4,9.1V4.9c0-0.3,0.3-0.5,0.6-0.3l3.6,2.1c0.3,0.2,0.3,0.5,0,0.7L7,9.4C6.7,9.6,6.4,9.4,6.4,9.1z" />
    </svg>
</template>

<script>
export default {
    props: {
        color: { type: String, default: "" },
        size: { type: String, default: "default" },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Size
.icon--size {
    &-xx-small {
        width: 10px;
    }
    &-x-small {
        width: 12px;
    }
    &-small {
        width: 14px;
    }
    &-default {
        width: 16px;
    }
    &-large {
        width: 18px;
    }
    &-x-large {
        width: 20px;
    }
    &-xx-large {
        width: 22px;
    }
}
</style>
