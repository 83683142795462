<template>
    <div class="scrolldown">
        <slot />
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
// Scrolldown
.scrolldown {
    color: var(--v-grey-darken4);
    text-align: center;
    // Scrolldown light
    &--light::v-deep {
        color: #fff;
    }
    // Scrolldown double-arrow
    &--double-arrow::v-deep {
        .scrolldown {
            &__arrow {
                display: flex;
                flex-direction: column;
                .icon-svg {
                    animation: ScrolldownDoubleArrow 2s infinite;
                    animation-delay: 0.2s;
                    & + .icon-svg {
                        animation-delay: unset;
                    }
                }
                & + .scrolldown__txt {
                    margin-top: 20px;
                }
            }
            &__txt {
                font-size: 1.4rem;
                & + .scrolldown__arrow {
                    margin-top: 8px;
                }
            }
        }
    }
    @keyframes ScrolldownDoubleArrow {
        0% {
            transform: translateY(0) rotate(180deg);
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            transform: translateY(10px) rotate(180deg);
            opacity: 0;
        }
    }
    // Scrolldown arrow
    &--arrow::v-deep {
        animation: ScrolldownArrow 0.8s infinite;
        .scrolldown {
            &__arrow + .scrolldown__txt,
            &__txt + .scrolldown__arrow {
                margin-top: 14px;
            }
            &__arrow {
                line-height: 0;
            }
            &__txt {
                font-size: 1.4rem;
            }
        }
    }
    @keyframes ScrolldownArrow {
        0% {
            transform: translateY(0%);
        }
        50% {
            transform: translateY(10%);
        }
        100% {
            transform: translateY(0%);
        }
    }
    // Scrolldown filled
    &--filled {
        ::v-deep {
            .scrolldown {
                &__bar + .scrolldown__txt,
                &__txt + .scrolldown__bar {
                    margin-top: 12px;
                }
                &__bar {
                    display: block;
                    position: relative;
                    width: 1px;
                    height: 60px;
                    background: rgba(17, 17, 17, 0.15);
                    margin: 0 auto;
                    overflow: hidden;
                    &::after {
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        transform: translateY(-100%);
                        background: currentColor;
                        animation: ScrolldownFilled 2s infinite;
                    }
                }
                &__txt {
                    font-size: 1.2rem;
                }
            }
        }
        // Scrolldown filled vertical
        &.scrolldown--vertical::v-deep,
        &.scrolldown--vertical-wide::v-deep {
            .scrolldown {
                &__txt {
                    writing-mode: vertical-lr;
                    transform: rotate(180deg);
                }
            }
        }
        &.scrolldown--vertical::v-deep {
            .scrolldown {
                &__txt {
                    text-align: left;
                    line-height: 1;
                }
            }
        }
        // Scrolldown filled vertical wide
        &.scrolldown--vertical-wide::v-deep {
            display: flex;
            .scrolldown {
                &__bar {
                    height: 120px;
                }
                &__txt {
                    text-align: right;
                    margin-right: 4px;
                    & + .scrolldown__bar {
                        margin-top: 0;
                    }
                }
            }
        }
        &.scrolldown--light::v-deep {
            .scrolldown {
                &__bar {
                    background: rgba(255, 255, 255, 0.15);
                }
            }
        }
    }
    @keyframes ScrolldownFilled {
        100% {
            transform: translateY(100%);
        }
    }
    // Scrolldown
    &--mouse::v-deep {
        .scrolldown {
            &__mouse + .scrolldown__txt,
            &__mouse + .scrolldown__arrow {
                margin-top: 12px;
            }
            &__mouse {
                position: relative;
                width: 24px;
                height: 40px;
                border: 1px solid currentColor;
                border-radius: 999px;
                text-align: center;
                margin: auto;
                &::after {
                    content: "";
                    position: absolute;
                    top: 10px;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    background-color: currentColor;
                    animation: ScrolldownMouse 2s infinite;
                }
            }
            &__txt {
                font-size: 1.2rem;
                letter-spacing: 0.25em !important;
            }
            &__arrow {
                display: flex;
                flex-direction: column;
                .icon-svg {
                    animation: ScrolldownDoubleArrow 2s infinite;
                    animation-delay: 0.2s;
                    & + .icon-svg {
                        animation-delay: unset;
                    }
                }
                & + .scrolldown__txt {
                    margin-top: 20px;
                }
            }
        }
    }
    @keyframes ScrolldownMouse {
        0% {
            transform: translateX(-50%) translateY(0);
        }

        80% {
            transform: translateX(-50%) translateY(10px);
        }
    }
}
</style>
