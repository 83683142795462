<template>
    <u-inline-group>
        <li v-for="info in infos" :key="info.title" :class="info.clear == true ? 'clear' : ''">
            <u-txt-default class="txt--xs white--text">
                {{ info.title }} <span class="grey--text text--lighten-1">{{ info.text }}</span>
            </u-txt-default>
        </li>
    </u-inline-group>
</template>

<script>
import UInlineGroup from "@/components/publish/styles/groups/u-inline-group.vue";
import UTxtDefault from "@/components/publish/styles/typography/u-txt-default.vue";

export default {
    props: {},
    components: {
        UInlineGroup,
        UTxtDefault,
    },
    data() {
        return {
            infos: [
                { title: "대표이사", text: "이하나비" },
                { title: "사업자등록번호", text: "738-87-01629" },
                { title: "문의번호", text: "02-2602-6665" },
                { title: "팩스", text: "031-942-4260" },
                { title: "이메일", text: "lumino816@naver.com" },
                { title: "주소", text: "경기도 파주시 월롱면 도감로 130", clear: true },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
