const digitLengthOf = (value) => value.replaceAll(/\D/g, "").length;

export const rules = {
    username: [(value) => !/[^a-z\d]/.test(value) || "아이디를 영문 소문자 및 숫자로만 작성해주세요", (value) => (value && value?.length > 3) || "아이디를 4자 이상으로 작성해주세요"],

    password: [(value) => /[~!@#$%^&*()_+|<>?:{}]/.test(value) || "비밀번호에 특수문자를 넣어주세요", (value) => /[a-zA-Z]/.test(value) || "비밀번호에 영문을 넣어주세요", (value) => /[0-9]/.test(value) || "비밀번호에 숫자를 넣어주세요", (value) => (value && 8 <= value.length && value.length <= 16) || "비밀번호를 8 ~ 16자 사이로 작성해주세요"],

    name: [(value) => (value && 1 < value?.length) || "이름을 입력해주세요"],

    email: [(value) => (value && /[^@]+@[^@]+\.[^@]+/.test(value)) || "이메일을 입력해주세요"],

    phone: [(value) => (value && [10, 11].includes(digitLengthOf(value)) && !value.includes("-")) || "연락처를 입력해주세요"],

    // file: [(value) => (!value || value.size < 5000000 || "* 5MB 이하의 PDF 파일만 등록이 가능합니다.")],

    birth: [(value) => (value && value?.length === 8) || "생년월일(YYYYMMDD)을 입력해주세요."]
};
