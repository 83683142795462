<template>
    <divider-group-primary>
        <li v-for="link in links" :key="link.title">
            <a v-if="link.href" :href="link.href" target="_blank" class="white--text">{{ link.title }}</a>
            <router-link v-else :to="link.to" class="white--text">{{ link.title }}</router-link>
        </li>
    </divider-group-primary>
</template>

<script>
import DividerGroupPrimary from "@/components/publish/parents/groups/divider-group-primary.vue";

export default {
    props: {},
    components: {
        DividerGroupPrimary
    },
    data() {
        return {
            links: [
                // { title: "회사소개", to: "" },
                // { title: "자주묻는질문", to: "" },
                { title: "개인정보처리방침", to: "/privacy-policy" },
                // { title: "이용약관", to: "" },
                { title: "공지사항", to: "/center/notice" },
            ],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
