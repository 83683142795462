import Vue from "vue";
import Vuetify from "vuetify";

// VCurrencyField
import VCurrencyField from "v-currency-field";
Vue.use(VCurrencyField, {
    locale: "kr-KR",
    decimalLength: 0,
    autoDecimalMode: false,
    defaultValue: 0,
    min: null,
    max: null,
    valueAsInteger: false,
    allowNegative: true,
});

Vue.use(Vuetify);

export const basicTheme = {
    themes: {
        light: {
            primary: {
                lighten5: "#E0EFF6",
                lighten4: "#B3D6EA",
                lighten3: "#80BBDC",
                lighten2: "#4DA0CD",
                lighten1: "#268BC3",
                base: "#0077B8",
                darken1: "#006FB1",
                darken2: "#0064A8",
                darken3: "#005AA0",
                darken4: "#004791",
                accent1: "#BDD8FF",
                accent2: "#8ABBFF",
                accent3: "#579DFF",
                accent4: "#3D8EFF",
            },
            secondary: {
                lighten5: "#E0F5F4",
                lighten4: "#B3E6E3",
                lighten3: "#80D6D1",
                lighten2: "#4DC5BF",
                lighten1: "#26B8B1",
                base: "#00ACA3",
                darken1: "#00A59B",
                darken2: "#009B91",
                darken3: "#009288",
                darken4: "#008277",
                accent1: "#AFFFF7",
                accent2: "#7CFFF1",
                accent3: "#49FFEC",
                accent4: "#30FFEA",
            },
            tertiary: {
                lighten5: "#F1F8E8",
                lighten4: "#DDEEC5",
                lighten3: "#C6E39F",
                lighten2: "#AFD779",
                lighten1: "#9ECF5C",
                base: "#8DC63F",
                darken1: "#85C039",
                darken2: "#7AB931",
                darken3: "#70B129",
                darken4: "#5DA41B",
                accent1: "#EBFFDB",
                accent2: "#CFFFA8",
                accent3: "#B3FF75",
                accent4: "#A5FF5C",
            },
            quarternary: {
                lighten5: "#F4F0E9",
                lighten4: "#E4DBC9",
                lighten3: "#D3C3A5",
                lighten2: "#C1AA81",
                lighten1: "#B39866",
                base: "#A6864B",
                darken1: "#9E7E44",
                darken2: "#95733B",
                darken3: "#8B6933",
                darken4: "#7B5623",
                accent1: "#FFE1BB",
                accent2: "#FFCA88",
                accent3: "#FFB455",
                accent4: "#FFA93B",
            },
            accent: {
                lighten5: "#E5ECFF",
                lighten4: "#BFD0FF",
                lighten3: "#94B1FF",
                lighten2: "#6991FF",
                lighten1: "#497AFF",
                base: "#2962FF",
                darken1: "#245AFF",
                darken2: "#1F50FF",
                darken3: "#1946FF",
                darken4: "#0F34FF",
            },
            grey: {
                lighten5: "#F7F7F7",
                lighten4: "#ececec",
                lighten3: "#ddd",
                lighten2: "#aaa",
                lighten1: "#999",
                base: "#666",
                darken1: "#555",
                darken2: "#444",
                darken3: "#333",
                darken4: "#111",
            },
            amber: {
                lighten1: "#f4c923",
            },
            success: "#4CAF50",
            warning: "#FFC107",
            error: "#FF5252",
            info: "#2196F3",
            anchor: "inherit",
        },
    },
    options: { customProperties: true },
};

export const consoleTheme = {
    themes: {
        light: {
            primary: "#00C73C",
            secondary: "#445163",
            accent: "#00C73C",
            info: "#2D3539",
            content: "#EDF0F5",
            anchor: "#333",
        },
    },
};

/**
 *
 * @param {(basicTheme|consoleTheme)} theme
 * @returns
 */
export default function (theme) {
    return new Vuetify({
        lang: {
            locales: { ko: require("vuetify/lib/locale/ko") },
            current: "ko",
        },
        breakpoint: {
            mobileBreakpoint: "md",
            thresholds: {
                xs: 576,
                sm: 768,
                md: 1024,
                lg: 1200,
            },
            scrollBarWidth: 0,
        },
        theme: theme || basicTheme,
    });
}
