export const attrs_input = {
    dense: true,
    outlined: true,
    hideDetails: true,
    persistentPlaceholder: true,
};

export const attrs_table = {
    "disable-sort": true,
    "disable-pagination": true,
    "hide-default-footer": true,
};

//  Client attrs preset
export const attrs_controls_common = {
    "hide-details": "auto",
};
export const attrs_input_common = {
    "hide-details": "auto",
};
export const attrs_file_input_common = {
    "hide-details": "auto",
};
export const attrs_select_common = {
    "hide-details": "auto",
    "menu-props": { contentClass: "v-select-menu" },
};
export const attrs_tabs_common = {
    grow: true,
    "hide-slider": true,
};
export const attrs_window_item_common = {
    transition: "none",
    "reverse-transition": "none",
};