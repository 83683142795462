var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', _vm._b({
    staticClass: "icon-svg icon-youtube",
    class: [_vm.color ? _vm.color : '', _vm.size ? 'icon--size-' + _vm.size : ''],
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 14"
    }
  }, 'svg', _vm.$attrs, false), [_c('path', {
    attrs: {
      "d": "M15.7,2.3c-0.2-0.7-0.7-1.2-1.4-1.4C13,0.6,8,0.6,8,0.6s-5,0-6.3,0.3C1.1,1.1,0.5,1.7,0.3,2.3C0,3.6,0,7,0,7 s0,3.4,0.3,4.7c0.2,0.7,0.7,1.2,1.4,1.4C3,13.4,8,13.4,8,13.4s5,0,6.3-0.3c0.7-0.2,1.2-0.7,1.4-1.4C16,10.4,16,7,16,7\n\tS16,3.6,15.7,2.3z M6.4,9.1V4.9c0-0.3,0.3-0.5,0.6-0.3l3.6,2.1c0.3,0.2,0.3,0.5,0,0.7L7,9.4C6.7,9.6,6.4,9.4,6.4,9.1z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }