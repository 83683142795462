<template>
    <div class="footer-foot">
        <v-container>
            <v-row v-bind="{ ...footRowAttrs }">
                <v-col v-if="this.$slots['default']" v-bind="{ ...footDefaultAttrs }">
                    <slot />
                </v-col>
                <v-col v-if="this.$slots['left']" v-bind="{ ...footLeftAttrs }">
                    <slot name="left" />
                </v-col>
                <v-col v-if="this.$slots['center']" v-bind="{ ...footCenterAttrs }">
                    <slot name="center" />
                </v-col>
                <v-col v-if="this.$slots['right']" v-bind="{ ...footRightAttrs }">
                    <slot name="right" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    props: {
        footRowAttrs: { type: Object, default: () => ({}) },
        footDefaultAttrs: { type: Object, default: () => ({}) },
        footLeftAttrs: { type: Object, default: () => ({}) },
        footCenterAttrs: { type: Object, default: () => ({}) },
        footRightAttrs: { type: Object, default: () => ({}) },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
