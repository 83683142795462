var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-body"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [this.$slots['default'] ? _c('v-col', [_vm._t("default")], 2) : _vm._e(), this.$slots['left'] ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("left")], 2) : _vm._e(), this.$slots['center'] ? _c('v-col', [_vm._t("center")], 2) : _vm._e(), !this.$slots['center'] && this.$slots['right'] ? _c('v-spacer') : _vm._e(), this.$slots['right'] ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("right")], 2) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }